import APIManager from './APIManager';

// ? Minor to Individual Account Transfer
const FillPHSTForm = async (queryObj) => {
    console.log(queryObj.letterOfConsents);
    const formData = new FormData();

    formData.append('name', queryObj.name);
    formData.append('email', queryObj.email);
    formData.append('deceasedName', queryObj.deceasedName);
    formData.append('dayofPassing', queryObj.dayofPassing);
    formData.append('district', queryObj.district);
    formData.append('relation', queryObj.relation);
    formData.append('companyName', queryObj.companyName);
    formData.append('accountNo', queryObj.accountNo);
    formData.append('securities', JSON.stringify(queryObj.securities));
    formData.append('phoneNumber', queryObj.phoneNumber);
    formData.append('signature', queryObj.signature);
    formData.append('deathCertificate', queryObj.deathCertificate);
    formData.append('deceasedCitizenship', queryObj.deceasedCitizenship);
    formData.append(
        'relationshipVerification',
        queryObj.relationshipVerification,
    );
    formData.append('citizenship', queryObj.citizenship);
    // formData.append('letterOfConsents', queryObj.letterOfConsents);
    // Append multi files as an array of objects
    for (let i = 0; i < queryObj.letterOfConsents.length; i++) {
        console.log('Test', queryObj.letterOfConsents[i]);
        formData.append('letterOfConsents', queryObj.letterOfConsents[i]);
    }

    const formResponse = APIManager.axios
        .post(`/api/v1/public/post-humous-security-transfer`, formData)
        .then((response) => {
            return response;
        });

    return formResponse;
};

export default {
    FillPHSTForm,
};
