<template>
  <v-container class="my-10 mb-8 pb-8 dcl-form">
    <v-dialog v-model="submitDialog" max-width="500px">
      <v-card>
        <v-card-title>{{ resMessage.title || 'Your form has been submitted' }}</v-card-title>
        <v-card-text>
          <p>{{ resMessage.message || 'You will get a copy of your form in the email you provided' }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="submitLoader" max-width="500px">
      <v-card>
        <v-card-title>Your form is being submitted</v-card-title>
        <v-card-text>
          <p>Your form is being processed. This might take some time...</p>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-snackbar v-model="snackBar.show" :timeout="3000" :color="snackBar.color" elevation="24" location="bottom right">
      <span class="snackbar__message">{{ snackBar.text }}</span>
    </v-snackbar>

    <div class="dcl__header">
      <h2><v-btn class="me-2" icon href="javascript:history.back()"><v-icon>mdi-chevron-left</v-icon></v-btn>
        Posthumous Securities Transfer</h2>
      <v-divider class="my-8"> </v-divider>
    </div>

    <div class="form-container">
      <v-form v-model="valid" ref="STForm">
        <v-container>
          <v-row dense justify="center">
            <v-col cols="12" md="10">
              <section class="pb-2">
                <h3>Applicant Details</h3>
                <p class="muted">Individual filling the form</p>
              </section>
            </v-col>
          </v-row>
          <!-- ? Name -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. John Doe" density="compact" v-model="clientInfo.name"
                  :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Email -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Email
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. j.doe@acme.co" density="compact"
                  v-model="clientInfo.email" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Address -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Applicant's Address
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Kathmandu, Nepal" density="compact"
                  v-model="clientInfo.address" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <v-row dense justify="center">
            <v-col cols="12" md="10">
              <section class="pb-2">
                <h3>Deceased Individual's Details</h3>
                <p class="muted">The account holder who has passed away</p>
              </section>
            </v-col>
          </v-row>

          <!-- ? Deceased Name -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Deceased Name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. George Doe" density="compact"
                  v-model="clientInfo.deceasedName" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>

          <!-- ? Day of Passing -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Date of Passing
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. 2023/1/1" density="compact"
                  v-model="clientInfo.dayofPassing" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Deceased District -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Deceased's District of Residence
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Kathmandu " density="compact"
                  v-model="clientInfo.district" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Applicant - Deceased Relation -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Applicant's Relation with Deceased
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Nephew " density="compact"
                  v-model="clientInfo.relation" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>

          <!-- ? Account Number -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Account Number
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. 1234567890" density="compact"
                  v-model="clientInfo.accountNo" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Phone Number -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Phone Number
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. 98XXXXXXXX" density="compact"
                  v-model="clientInfo.phoneNumber"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Telephone Number -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Telephone Number
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. 01-XXXXXXX" density="compact"
                  v-model="clientInfo.telephoneNumber"></v-text-field>
              </section>
            </v-col>
          </v-row>

          <v-row dense justify="center">
            <v-col cols="12" md="10">
              <section class="pb-2">
                <h3>Security Details</h3>
                <p class="muted">Details of Shares/ Securities</p>
              </section>
            </v-col>
          </v-row>
          <!-- ? Company Name -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Company name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Acme Co. " density="compact"
                  v-model="clientInfo.companyName" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Aktien -->
          <v-row dense justify="center" class="mb-5">
            <v-col cols="12" md="10">
              <span class="text--darken-3 inputLabel">Securities</span>
            </v-col>
            <v-col cols="12" md="10">
              <table>
                <!-- <template v-slot:default> -->
                <thead>
                  <tr>
                    <td rowspan="2" width="5%" class="text-center">
                      S.N.
                    </td>
                    <td rowspan="2" width="30%" class="text-center">
                      Share Certificate Number
                    </td>
                    <td colspan="2" class="text-center">
                      Serial Number
                    </td>
                    <td class="text-center" rowspan="2" width="10%">
                      Quantity
                    </td>
                    <td class="text-center" rowspan="2" width="10%">
                      Remarks
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center" width="15%">
                      From
                    </td>
                    <td class="text-center" width="15%">
                      To
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="sec, index in clientInfo.securities" :key="index">
                    <td>{{ sec.index }}</td>
                    <td>{{ sec.securityNo }}</td>
                    <td>{{ sec.from }}</td>
                    <td>{{ sec.to }}</td>
                    <td>{{ sec.securityQuantity }}</td>
                    <td>{{ sec.drn }}</td>
                  </tr>
                </tbody>
                <!-- </template> -->
              </table>
              <v-row class="mt-4">
                <v-col cols="12" sm="12" md="4">
                  <v-text-field variant="outlined" label="Name" density="compact"
                    v-model="security.securityNo"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-text-field variant="outlined" label="From" density="compact" v-model="security.from"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-text-field variant="outlined" label="To" density="compact" v-model="security.to"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-text-field variant="outlined" label="Quantity" density="compact"
                    v-model="security.securityQuantity"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-text-field variant="outlined" label="Remarks" density="compact"
                    v-model="security.drn"></v-text-field>
                </v-col>
              </v-row>
              <v-btn style="width: 100%; text-transform: capitalize; letter-spacing: 0px;" elevation="0" color="success"
                @click="addSecurity"><v-icon left>mdi-plus</v-icon> Add</v-btn>
            </v-col>
          </v-row>
          <!-- ? Applicant Signature -->
          <v-row dense justify="center">
            <v-col cols="12" md="10">
              <span class="text--darken-3 inputLabel">Applicant's Signature</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-file-input accept="image/*" flat density="compact" append-icon="mdi-paperclip" prepend-icon=""
                class="text--blue" dense v-model="clientInfo.signature" :rules="stringSizeRule"></v-file-input>
            </v-col>
          </v-row>
          <!-- ? Death Certificate -->
          <v-row dense justify="center">
            <v-col cols="12" md="10">
              <span class="text--darken-3 inputLabel">Death Certificate of the Deceased</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-file-input accept="image/*" flat density="compact" append-icon="mdi-paperclip" prepend-icon=""
                class="text--blue" dense v-model="clientInfo.deathCertificate" :rules="stringSizeRules"></v-file-input>
            </v-col>
          </v-row>
          <!-- ? Dead Citizenship -->
          <v-row dense justify="center">
            <v-col cols="12" md="10">
              <span class="text--darken-3 inputLabel">Citizenship of the Deceased</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-file-input accept="image/*" flat density="compact" append-icon="mdi-paperclip" prepend-icon=""
                class="text--blue" dense v-model="clientInfo.deceasedCitizenship" :rules="stringSizeRules"></v-file-input>
            </v-col>
          </v-row>
          <!-- ? Relationship Verification -->
          <v-row dense justify="center">
            <v-col cols="12" md="10">
              <span class="text--darken-3 inputLabel">Relationship Verification of the Application to the Deceased</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-file-input accept="image/*" flat density="compact" append-icon="mdi-paperclip" prepend-icon=""
                class="text--blue" dense v-model="clientInfo.relationshipVerification"
                :rules="stringSizeRules"></v-file-input>
            </v-col>
          </v-row>
          <!-- ? Relationship Verification -->
          <v-row dense justify="center">
            <v-col cols="12" md="10">
              <span class="text--darken-3 inputLabel">Letter of Consent from Other Beneficiaries</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-file-input accept="image/*" flat density="compact" append-icon="mdi-paperclip" prepend-icon=""
                class="text--blue" dense multiple v-model="clientInfo.letterOfConsents" :rules="sizeRule"></v-file-input>
            </v-col>
          </v-row>
          <!-- ? Applicant's Citizenship -->
          <v-row dense justify="center">
            <v-col cols="12" md="10">
              <span class="text--darken-3 inputLabel">Applicant's Citizenship</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-file-input accept="image/*" flat density="compact" append-icon="mdi-paperclip" prepend-icon=""
                class="text--blue" dense v-model="clientInfo.citizenship" :rules="stringSizeRules"></v-file-input>
            </v-col>
          </v-row>
          <!-- ? Submit -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <v-btn elevation="0" color="success" style="text-transform: capitalize; letter-spacing: 0px;"
                  @click="submit">Submit
                  <v-icon right>mdi-content-save</v-icon></v-btn>
              </section>
            </v-col>
          </v-row>

        </v-container>

      </v-form>
    </div>
  </v-container>
</template>

<script>
import PHSTService from "../services/API/PHSTService"
export default {
  name: "PHST",
  data() {
    return {
      valid: false,
      submitDialog: false,
      submitLoader: false,
      snackBar: {
        show: false,
        color: '',
        text: ''
      },
      resMessage: {
        title: null,
        message: null
      },
      security: {
        "index": "",
        "securityNo": "",
        "from": "",
        "to": "",
        "securityQuantity": "",
        "drn": ""
      },
      clientInfo: {
        name: '',
        email: '',
        deceasedName: '',
        dayofPassing: '',
        district: '',
        relation: '',
        companyName: '',
        accountNo: '',
        securities: [],
        phoneNumber: '',
        signature: '',
        deathCertificate: '',
        deceasedCitizenship: '',
        relationshipVerification: '',
        citizenship: '',
        letterOfConsents: ''
      },
      stringRules: [
        v => !!v || "This field is required",
      ],
      stringSizeRule: [
        v => !!v && v.size < 1000000 || 'File is required and the file size should not exceed 1MB!'
      ],
      sizeRule: [
        v => (v ? v.size < 1000000 : true) || 'File size should not exceed 1MB!'
      ]
    }
  },
  methods: {
    addSecurity() {
      if (this.security.securityNo && this.security.from && this.security.to && this.security.securityQuantity) {
        this.clientInfo.securities.push({
          index: this.clientInfo.securities.length + 1,
          securityNo: this.security.securityNo,
          from: this.security.from,
          to: this.security.to,
          securityQuantity: this.security.securityQuantity,
          drn: this.security.drn,
        });
        this.security.securityNo = ''
        this.security.from = ''
        this.security.to = ''
        this.security.securityQuantity = ''
        this.security.drn = ''
      } else {
        this.snackBar.show = true;
        this.snackBar.color = 'red';
        this.snackBar.text = 'Please enter the necessary information'
      }
    },
    async submit() {
      if (this.$refs.STForm.validate() && this.clientInfo.securities.length > 0) {
        this.submitLoader = true;
        await PHSTService.FillPHSTForm(this.clientInfo).then((response) => {
          if (response.status != 200) {
            this.resMessage.title = "Could Not Submit Form"
            this.resMessage.message = "There was an error while submitting this form"
          }

          this.submitLoader = false;
          this.submitDialog = true;
        })
      } else {
        this.snackBar.show = true;
        this.snackBar.color = 'red';
        this.snackBar.text = 'Fill all the fields and add at least one security info for transfer.'
      }

    }
  }
}
</script>

<style scoped>
.form-container {
  max-width: 800px;
  margin-inline: auto;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0.4rem;
  padding-block: 2rem;
}

.inputLabel {
  font-weight: 600 !important;
  font-size: 0.9rem;
}

.inputTitle {
  font-weight: 600 !important;
  font-size: 1.2rem;
}

.dcl__header {
  max-width: 800px;
  margin-inline: auto;
}

table {
  border-collapse: collapse;
}

td,
th {
  border: 1px solid rgb(168, 168, 168);
  border-collapse: collapse;
  text-align: center;
}
</style>
